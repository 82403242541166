import React, { useEffect, useState } from "react";
import { message, Modal, Spin } from "antd";
import { IoMdCheckmarkCircle } from "@react-icons/all-files/io/IoMdCheckmarkCircle";
import "./MessageSuccessPopup.scss";
import { useSelector } from "react-redux";
import { registerProspectSelector } from "../../RegisterProspect/RegisterProspectSlice";
import { useAppSelector } from "../../../../hooks";
import { getUniversityResourceSelector } from "../../../../MainScreen/MainScreenSlice";
import { useTranslation } from "react-i18next";
import PageLoader from "../../../PageLoader";

const MessageSuccessPopup = ({
  isModalVisible,
  setIsModalVisible,
  prospectData,
  buddies,
}: any) => {
  const [] = useState(true);
  const { status, isFetching, sessionDetails } = useSelector(
    registerProspectSelector
  );
  const { payload } = useAppSelector(getUniversityResourceSelector);

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const info = () => {
    message.info("Content copied to clipboard");
  };

  useEffect(() => {
    // console.log("sessionDetails", sessionDetails);
  }, [sessionDetails]);

  setTimeout(() => {
    setIsModalVisible(false);
  }, 10000);

  const { t } = useTranslation();
  return (
    <>
      <Modal
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        {isFetching ? (
          <PageLoader />
        ) : (
          <div className="messageSuccessPopupTitle">
            <div className="PopupTitleIconHeaderLayout">
              <div className="PopupTitleIcon">
                <IoMdCheckmarkCircle size={50} />
              </div>
              <div className="titleHeaderLayout">
                <div className="titleHeader">
                  {t("dashboard.MessageSuccessPopup.titleHeader")}
                </div>
                {/* <div className="secondTitleText">
                  {t("dashboard.MessageSuccessPopup.secondTitleText")}{" "}
                  {sessionDetails?.defaultReplyTime}
                </div> */}
              </div>
            </div>
            <div className="titleHeaderSub">
              {t("dashboard.MessageSuccessPopup.titleHeaderSub1")} {""}{" "}
              {prospectData?.email} {""}{" "}
              {t("dashboard.MessageSuccessPopup.titleHeaderSub2")} {""}{" "}
              {buddies?.profile.firstName} {""}
              {t("dashboard.MessageSuccessPopup.titleHeaderSub3")}. {""}{" "}
              {t("dashboard.MessageSuccessPopup.titleHeaderSub4")}
            </div>
            <div className="referingToFriendText">
              {t("dashboard.MessageSuccessPopup.referingToFriendText")}
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};

export default MessageSuccessPopup;
