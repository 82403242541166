import { Button, Divider, Input, Radio, Space, Spin } from 'antd';
import React, { memo, useState } from 'react';
import './TypeMessageContainer.scss';
import { BsChatLeftTextFill } from 'react-icons/bs';
import { MdLock } from 'react-icons/md';
import { RiStarSFill } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { getUniversityResourceSelector } from '../../../../MainScreen/MainScreenSlice';
import { SearchOutlined } from '@ant-design/icons';
import type { RadioChangeEvent } from 'antd';
import { MultipleSelect } from '../../../common/MultipleSelect/MultipleSelect';
import MultiSelectDropdown from '../../../common/MultipleSelect/CustomMaltiSelect';
import { useSelector } from 'react-redux';
import { setSelectedCourse, setHasApplied, setMessageContent, setCourseId } from './SayHelloPopupSlice';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';

const { TextArea } = Input;
const TypeMessageContainer = ({
  messageState,
  setMessageState,
  structureProgrammes,
  setApplicationNumber,
  setSelectedProgrammes,
  isLoggedIn,
  enableWordPress,
}: any) => {
  const { t } = useTranslation();
  const { isFetching, status, payload } = useAppSelector(getUniversityResourceSelector);
  const universityConfigResponse = useSelector(getUniversityResourceSelector);

  const dispatch = useAppDispatch();
  const prospectSelectedCourses = useAppSelector((state) => state.chatPopups.selectedCourse);

  const isAlreadyApplied = useAppSelector((state) => state.chatPopups.hasApplied);

  const handleCourses = (selectedCourses: CheckboxValueType[]) => {
    // Mapping selected course values to strings
    const courseIds = selectedCourses.map((course) => course.toString());
    console.log('Selected Course IDs:', courseIds);
    dispatch(setSelectedCourse(courseIds));
    dispatch(setCourseId(courseIds));
  };

  const [buttonArray, setButtonArray] = useState(payload?.chatSuggestions);
  // const [isAlreadyApplied, setIsAlreadyApplied] = useState(1);
  // const [selectedCourse, setSelectedCourse] = useState<any>([]);

  const onButtonClickSuggestion = (id: number, message: string) => {
    // console.log("index", id);
    setButtonArray(payload?.chatSuggestions.filter((item, index) => item.id != id));
    setMessageState(message);
  };

  const onChangeTypeMessageContent = (content: string) => {
    setMessageState(content);
    setButtonArray(payload?.chatSuggestions);
  };

  const onChangeRadio = (e: RadioChangeEvent) => {
    dispatch(setHasApplied(Number(e.target.value)));
  };

  const handleApplicationNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    setApplicationNumber(e.target.value);
  };

  return (
    <>
      {/* <div className="textAreaHeader">
        <div className="messageHeading">
          {t("dashboard.TypeMessageContainer.messageHeading")}
          <div>
            <RiStarSFill className="starIcon" size={8} />
          </div>
        </div>
      </div> */}
      <div className="typeMessageContainer">
        <TextArea
          className={enableWordPress ? 'textArea-iFrame' : 'textArea'}
          placeholder={t('dashboard.TypeMessageContainer.textAreaHintText') || ''}
          autoFocus={true}
          value={messageState}
          onChange={(e) => onChangeTypeMessageContent(e.target.value)}
        />
        <Divider />
        <div>
          <div className="questionsHeading">You can ask me about</div>
          <div className="suggestionButtonsContainer">
            {buttonArray?.map((suggestion, index) => {
              return (
                <Button
                  key={index}
                  type="primary"
                  style={{
                    color: `${payload?.theme?.themeColor}`,
                    borderColor: `${payload?.theme?.themeColor}`,
                  }}
                  className={enableWordPress ? 'suggestionButton-iFrame' : 'suggestionButton'}
                  onClick={() => onButtonClickSuggestion(suggestion.id, `${suggestion?.message}`)}
                  ghost
                >
                  <div className="textContainer">
                    <span className="textSpan">
                      <BsChatLeftTextFill />
                    </span>
                    <div className="suggestionText">{suggestion?.message}</div>
                  </div>
                </Button>
              );
            })}
          </div>
        </div>
      </div>

      {!isLoggedIn && (
        <div className="optionsQuestion">
          <div className="optionsQuestionTitle">Course Interest ?</div>
          <div
            style={{
              marginTop: '10px',
            }}
          >
            <MultiSelectDropdown
              options={structureProgrammes}
              onChange={handleCourses}
              value={prospectSelectedCourses}
            />
          </div>
          {/* <div className="optionsQuestionTitle">{`Have you already applied to ${payload?.name} ?`}</div>
          <div className="optionsQuestionCheckboxGroup">
            <Radio.Group className="radioGroup" onChange={onChangeRadio} defaultValue={isAlreadyApplied}>
              <Radio value={1}>
                <div className="radioGroupText">Yes</div>
              </Radio>
              <Radio value={2}>
                <div className="radioGroupText">No</div>
              </Radio>
            </Radio.Group>
          </div> */}
          {/* {isAlreadyApplied === 1 ? (
            <>
              {!!universityConfigResponse &&
                !!universityConfigResponse.payload &&
                universityConfigResponse.payload.univeristyConfig.applicationNumEnabled && (
                  <>
                    <div className="optionsQuestionAsnwerTitle">Enter your application number</div>
                    <div>
                      <Input
                        placeholder="Application number"
                        onChange={handleApplicationNumber}
                        className="optionsQuestionAsnwerTextbox"
                      />
                    </div>
                  </>
                )}
            </>
          ) : (
            <>
              <div className="optionsQuestionAsnwerTitle">Which courses are you interested in?</div>{' '}
              <div
                style={{
                  marginTop: '10px',
                }}
              >
                <MultiSelectDropdown
                  options={structureProgrammes}
                  onChange={handleCourses}
                  value={prospectSelectedCourses}
                />
              </div>
            </>
          )} */}
        </div>
      )}

      {/* <div className="textArea" style={{ position: "relative" }}>
        <TextArea
          className="textAreaIn"
          placeholder={t("dashboard.TypeMessageContainer.textAreaHintText") || ""}
          rows={17}
          autoFocus={true}
          value={messageState}
          onChange={(e) => onChangeTypeMessageContent(e.target.value)}
        />

        <Divider className="textAreaDividerIn" />

        <div className="aiButtonLayoutMain">
          <Spin spinning={isFetching}>
            <div className="aiButtonLayoutRow">
              <div className="aiButtonHintLayout">{t("dashboard.TypeMessageContainer.aiButtonHintText")}</div>
              {buttonArray?.map((suggestion, index) => {
                return (
                  <Button
                    key={index}
                    className="aiButton1 "
                    type="primary"
                    icon={<BsChatLeftTextFill />}
                    style={{
                      color: `${payload?.theme?.themeColor}`,
                      borderColor: `${payload?.theme?.themeColor}`,
                    }}
                    onClick={() => onButtonClickSuggestion(suggestion.id, `${suggestion?.message}`)}
                    ghost
                  >
                    <div
                      className="inUserChatText inUserChatTextMobile"
                      style={{
                        color: `${payload?.theme?.themeColor}`,
                      }}
                    >
                      {suggestion?.message}
                    </div>
                  </Button>
                );
              })}
            </div>
          </Spin>
        </div>
      </div> */}
    </>
  );
};

export default memo(TypeMessageContainer);
