import moment from 'moment';

const chatMessageDateFormat = 'MMM Do YYYY, hh:mm A';

export const changeTimeToCurrentTimezone = (date: any, minOffset: any) => {
  if (minOffset) {
    let currentOffset = moment().utcOffset();
    let tempTime = new Date(date).getTime() + (-minOffset + currentOffset) * 60000;

    return moment(tempTime).format(chatMessageDateFormat);
  }

  return moment(date).format(chatMessageDateFormat);
};

export const minutesFormatter = (time: any) => {
  const sec = (time % 1) * 60;
  const min = time - (time % 1);

  const hour = Math.floor(min / 60);
  const qMin = min - hour * 60;

  return `${hour > 0 ? hour + ' hours' : ''} ${qMin > 0 ? qMin + 'mins' : ''} ${sec - (sec % 1)} sec`;
};
