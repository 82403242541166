import { Descriptions, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

export const ProfileDescription = ({ buddyView }: any) => {
  //console.log('=== buddyView ===', buddyView);

  const renderBuddyDescription = (type: string) => {
    let buddyType = type.toLowerCase();
    switch (buddyType) {
      case 'student':
        return <StudentDescription buddyView={buddyView} />;
      case 'staff':
        return <StaffDescription buddyView={buddyView} />;
      case 'alumni':
        return <AlumniDescription buddyView={buddyView} />;
      case 'parent':
        return <ParentDescription buddyView={buddyView} />;
      default:
        break;
    }
  };
  return <>{renderBuddyDescription(buddyView?.profile?.type)}</>;
};

const InterestSection = ({ interests }: any) => {
  const interestsCount = interests.length || 0;

  return (
    <div className="hobbyIn">
      <div className="hobbyIcon">
        {interests.map(({ url, name }: any, index: number) => (
          <div key={index}>
            {interestsCount > 3 ? (
              <Tooltip title={name} placement="bottom">
                <img src={url} alt={name} />
              </Tooltip>
            ) : (
              <>
                <img src={url} alt={name} />
                <span style={{ marginRight: '5px' }}>{name}</span>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

const LanguageSection = ({ languages }: { languages: { code: string; name: string; url: string }[] }) => {
  const languagesCount = languages.length;

  return (
    <div className="languageSection">
      {languages.slice(0, 3).map((lang, index) => (
        <span className="lang" key={index}>
          {lang.name}
          {''}
        </span>
      ))}
      {languagesCount > 3 && (
        <Tooltip
          placement="top"
          title={languages
            .slice(3)
            .map((lang) => lang.name)
            .join(', ')}
        >
          <span>+{languagesCount - 3}</span>
        </Tooltip>
      )}
    </div>
  );
};

const StudentDescription = ({ buddyView }: any) => {
  const { t } = useTranslation();
  //console.log('===Buddy data===', buddyView);
  return (
    <>
      <Descriptions>
        <Descriptions.Item label={t('ViewProfile.buddyName')} span={3}>
          {buddyView?.profile?.displayName}
        </Descriptions.Item>
        <Descriptions.Item label={t('ViewProfile.buddyProgramme')} span={3}>
          {buddyView?.profile?.programme}
        </Descriptions.Item>
        <Descriptions.Item label={t('ViewProfile.buddyCourse')} span={3}>
          {buddyView?.profile?.courseInfo?.displayName}
        </Descriptions.Item>
        <Descriptions.Item label={t('ViewProfile.buddyCourseYear')} span={3}>
          {buddyView?.profile?.year}
        </Descriptions.Item>
        <Descriptions.Item label={t('ViewProfile.expectedGraduationYear')} span={3}>
          {buddyView?.profile?.graduatedYear}
        </Descriptions.Item>
        {/* <Descriptions.Item label={t('ViewProfile.languageISpeak')} span={3}>
          {buddyView?.profile?.languages.map((lang: { code: string; name: string; url: string }) => {
            return <span className="lang">{lang.name}</span>;
          })}
        </Descriptions.Item> */}
        <Descriptions.Item label={t('ViewProfile.languageISpeak')} span={3}>
          <LanguageSection languages={buddyView?.profile?.languages} />
        </Descriptions.Item>
        {/* <Descriptions.Item label={t('ViewProfile.buddyInterest')} span={3}>
          <div className="hobbyIn">
            <div className="hobbyIcon">
              {buddyView?.profile?.interests.map(({ url, name }: any) => {
                return (
                  <Tooltip placement="bottom" title={name}>
                    <img src={url} />
                  </Tooltip>
                );
              })}
            </div>
          </div>
        </Descriptions.Item> */}
        <Descriptions.Item label={t('ViewProfile.buddyInterest')} span={3}>
          <InterestSection interests={buddyView?.profile?.interests} />
        </Descriptions.Item>

        <Descriptions.Item label={t('ViewProfile.currentLiviningIn')} span={3}>
          <div className="userLangugeImg">
            {/* <img alt="example" src={buddyView.profile?.country.flagUrl} /> */}
            <span className={`fi fi-${buddyView?.profile?.country?.code.toLowerCase()}`}></span>
            {buddyView?.profile?.country.name}
          </div>
        </Descriptions.Item>
        <Descriptions.Item label={t('ViewProfile.buddyAbout')} span={3}>
          {buddyView?.profile?.description}
        </Descriptions.Item>
      </Descriptions>
    </>
  );
};

const StaffDescription = ({ buddyView }: any) => {
  const { t } = useTranslation();
  return (
    <>
      <Descriptions>
        <Descriptions.Item label={t('ViewProfile.buddyName')} span={3}>
          {buddyView?.profile?.displayName}
        </Descriptions.Item>
        <Descriptions.Item label={t('ViewProfile.currentRole')} span={3}>
          {buddyView?.profile?.role}
        </Descriptions.Item>
        <Descriptions.Item label={t('ViewProfile.department')} span={3}>
          {buddyView?.profile?.department}
        </Descriptions.Item>
        <Descriptions.Item label={t('ViewProfile.languageISpeak')} span={3}>
          <LanguageSection languages={buddyView?.profile?.languages} />
        </Descriptions.Item>
        <Descriptions.Item label={t('ViewProfile.buddyInterest')} span={3}>
          <InterestSection interests={buddyView?.profile?.interests} />
        </Descriptions.Item>

        <Descriptions.Item label={t('ViewProfile.currentLiviningIn')} span={3}>
          <div className="userLangugeImg">
            {/* <img alt="example" src={buddyView.profile?.country.flagUrl} /> */}
            <span className={`fi fi-${buddyView?.profile?.country?.code.toLowerCase()}`}></span>
            {buddyView?.profile?.country.name}
          </div>
        </Descriptions.Item>
        <Descriptions.Item label={t('ViewProfile.buddyAbout')} span={3}>
          {buddyView?.profile?.description}
        </Descriptions.Item>
      </Descriptions>
    </>
  );
};

const AlumniDescription = ({ buddyView }: any) => {
  const { t } = useTranslation();
  return (
    <>
      <Descriptions>
        <Descriptions.Item label={t('ViewProfile.buddyName')} span={3}>
          {buddyView?.profile?.displayName}
        </Descriptions.Item>
        <Descriptions.Item label={t('ViewProfile.programmeStudied')} span={3}>
          {buddyView?.profile?.programme}
        </Descriptions.Item>
        <Descriptions.Item label={t('ViewProfile.buddyCourse')} span={3}>
          {buddyView?.profile?.courseInfo?.displayName}
        </Descriptions.Item>
        <Descriptions.Item label={t('ViewProfile.graduatedYear')} span={3}>
          {buddyView?.profile?.graduatedYear}
        </Descriptions.Item>
        <Descriptions.Item label={t('ViewProfile.currentRole')} span={3}>
          {buddyView?.profile?.role}
        </Descriptions.Item>
        <Descriptions.Item label={t('ViewProfile.companyName')} span={3}>
          {buddyView?.profile?.companyName}
        </Descriptions.Item>
        <Descriptions.Item label={t('ViewProfile.languageISpeak')} span={3}>
          <LanguageSection languages={buddyView?.profile?.languages} />
        </Descriptions.Item>
        <Descriptions.Item label={t('ViewProfile.buddyInterest')} span={3}>
          <InterestSection interests={buddyView?.profile?.interests} />
        </Descriptions.Item>

        <Descriptions.Item label={t('ViewProfile.currentLiviningIn')} span={3}>
          <div className="userLangugeImg">
            {/* <img alt="example" src={buddyView.profile?.country.flagUrl} /> */}
            <span className={`fi fi-${buddyView?.profile?.country?.code.toLowerCase()}`}></span>
            {buddyView?.profile?.country.name}
          </div>
        </Descriptions.Item>
        <Descriptions.Item label={t('ViewProfile.buddyAbout')} span={3}>
          {buddyView?.profile?.description}
        </Descriptions.Item>
      </Descriptions>
    </>
  );
};

const ParentDescription = ({ buddyView }: any) => {
  const { t } = useTranslation();
  return (
    <>
      <Descriptions>
        <Descriptions.Item label={t('ViewProfile.buddyName')} span={3}>
          {buddyView?.profile?.displayName}
        </Descriptions.Item>
        <Descriptions.Item label={t('ViewProfile.currentRole')} span={3}>
          {buddyView?.profile?.role}
        </Descriptions.Item>
        <Descriptions.Item label={t('ViewProfile.companyName')} span={3}>
          {buddyView?.profile?.companyName}
        </Descriptions.Item>
        <Descriptions.Item label={t('ViewProfile.languageISpeak')} span={3}>
          <LanguageSection languages={buddyView?.profile?.languages} />
        </Descriptions.Item>
        <Descriptions.Item label={t('ViewProfile.buddyInterest')} span={3}>
          <InterestSection interests={buddyView?.profile?.interests} />
        </Descriptions.Item>

        <Descriptions.Item label={t('ViewProfile.currentLiviningIn')} span={3}>
          <div className="userLangugeImg">
            {/* <img alt="example" src={buddyView.profile?.country.flagUrl} /> */}
            <span className={`fi fi-${buddyView?.profile?.country?.code.toLowerCase()}`}></span>
            {buddyView?.profile?.country.name}
          </div>
        </Descriptions.Item>
        <Descriptions.Item label={t('ViewProfile.buddyAbout')} span={3}>
          {buddyView?.profile?.description}
        </Descriptions.Item>
      </Descriptions>
    </>
  );
};
